import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

const Dashboard = React.lazy(() => import('../views/dashboard/dashboard'))
const Login = React.lazy(() => import('../views/login/login'))
const ForgotPassword = React.lazy(() => import('../views/login/forgot-password'))
const Workers = React.lazy(() => import('../views/management/workers/workers'))
const WorkersForm = React.lazy(() => import('../views/management/workers/workers-form'))
const WorkersDetail = React.lazy(() => import('../views/management/workers/workers-detail'))
const Residences = React.lazy(() => import('../views/management/residences/residences'))
const ResidenceCRUD = React.lazy(() => import('../views/management/residences/residence'))
const MultipleResidenceForm = React.lazy(() => import('../views/management/residences/multiple-residence-form'))
const ResidenceDetail = React.lazy(() => import('../views/management/residences/residence-detail'))
const CommonAreas = React.lazy(() => import('../views/management/common-areas/common-areas'))
const CommonAreaForm = React.lazy(() => import('../views/management/common-areas/common-area-form'))
const Votings = React.lazy(() => import('../views/management/votings/votings'))
const VotingsForm = React.lazy(() => import('../views/management/votings/votings-form'))
const VotingsResults = React.lazy(() => import('../views/management/votings/result/votings-result'))
const Tickets = React.lazy(() => import('../views/tickets/tickets'))
const TicketForm = React.lazy(() => import('../views/tickets/ticket-form'))
const Messages = React.lazy(() => import('../views/messages/messages'))
const Calendar = React.lazy(() => import('../views/calendar/calendar'))
const Visitors = React.lazy(() => import('../views/visitors/visitors'))
const VisitorsLogs = React.lazy(() => import('../views/visitors/logs'))
const EntriesLogs = React.lazy(() => import('../views/visitors/logs/entries'))
const PackagesLogs = React.lazy(() => import('../views/visitors/logs/packages'))
const NotesLogs = React.lazy(() => import('../views/visitors/logs/notes'))
const Documents = React.lazy(() => import('../views/documents/documents'))
const Meeting = React.lazy(() => import('../views/management/meetings/meeting'))
const Meetings = React.lazy(() => import('../views/management/meetings/meetings'))
const QrCode = React.lazy(() => import('../views/visitors/qr-code'))
const ReportsForm = React.lazy(() => import('../views/reports/reports-form'))
const Reports = React.lazy(() => import('../views/reports/reports'))
const Report = React.lazy(() => import('../views/reports/report'))
const EmployeeSignup = React.lazy(() => import('../views/signup/employee-signup'))
const TransactionForm = React.lazy(() => import('../views/accounting/transaction-form'))
const ApproveExecuteReceiveTransaction = React.lazy(() => import('../views/accounting/approve-execute-receive-transaction'))
const AccountingHistory = React.lazy(() => import('../views/accounting/accounting-history'))
const RecurrenciesTransaction = React.lazy(() => import('../views/accounting/recurrency-transaction'))
const CondominiumSettings = React.lazy(() => import('../views/management/condominium-settings'))
const Checklists = React.lazy(() => import('../views/checklists/checklists'))
const ChecklistNew = React.lazy(() => import('../views/checklists/checklist-new'))
const ChecklistFill = React.lazy(() => import('../views/checklists/checklist-fill'))
const Equipments = React.lazy(() => import('../views/management/equipments/equipments'))
const EquipmentNew = React.lazy(() => import('../views/management/equipments/equipment-form'))
const Equipment = React.lazy(() => import('../views/management/equipments/equipment'))
const CarTracker = React.lazy(() => import('../views/visitors/tracker/car-tracker'))
const NewCarTracker = React.lazy(() => import('../views/visitors/tracker/new-car-tracker'))
const Ranking = React.lazy(() => import('../views/ranking/ranking'))
const RankingNew = React.lazy(() => import('../views/ranking/ranking-new'))

const Routes: FC = () => {
  return (
    <Switch>
      <Route
        path="/management/condominium-settings"
        render={(props: any) => (
          <CondominiumSettings {...props}/>
        )}
      />
      <Route
        path="/management/common-areas/new"
        render={(props: any) => (
          <CommonAreaForm {...props}/>
        )}
      />
      <Route
        path="/management/common-areas"
        render={(props: any) => (
          <CommonAreas {...props}/>
        )}
      />
      <Route
        path="/management/residences/residence-info/:id"
        render={(props: any) => (
          <ResidenceCRUD {...props}/>
        )}
      />
      <Route
        path="/management/residences/new"
        render={(props: any) => (
          <MultipleResidenceForm {...props}/>
        )}
      />
      <Route
        path="/management/residences/:id"
        render={(props: any) => (
          <ResidenceDetail {...props}/>
        )}
      />
      <Route
        path="/management/residences"
        render={(props: any) => (
          <Residences {...props}/>
        )}
      />
      <Route
        path="/management/workers/new"
        render={(props: any) => (
          <WorkersForm {...props}/>
        )}
      />
        <Route
          path="/management/workers/:id"
          render={(props: any) => (
            <WorkersDetail {...props}/>
          )}
        />
      <Route
        path="/management/workers"
        render={(props: any) => (
          <Workers {...props}/>
        )}
      />
      <Route
        path="/management/equipments/form"
        render={(props: any) => (
          <EquipmentNew {...props}/>
        )}
      />
      <Route
        path="/management/equipments/:id"
        render={(props: any) => (
          <Equipment {...props}/>
        )}
      />
      <Route
        path="/management/equipments"
        render={(props: any) => (
          <Equipments {...props}/>
        )}
      />
      <Route
        path="/login"
        render={(props: any) => (
          <Login {...props}/>
        )}
      />
      <Route
        path="/forgot-password"
        render={(props: any) => (
          <ForgotPassword {...props}/>
        )}
      />
      <Route
        path="/reports/new"
        render={(props: any) => (
          <ReportsForm {...props}/>
        )}
      />
            <Route
        path="/reports/:id"
        render={(props: any) => (
          <Report {...props}/>
        )}
      />
      <Route
        path="/reports"
        render={(props: any) => (
          <Reports {...props}/>
        )}
      />
      <Route
        path="/votings/new"
        render={(props: any) => (
          <VotingsForm {...props}/>
        )}
      />
      <Route
        path="/votings/:id"
        render={(props: any) => (
          <VotingsResults {...props}/>
        )}
      />
      <Route
        path="/votings"
        render={(props: any) => (
          <Votings {...props}/>
        )}
      />
      <Route
        path="/ranking/new"
        render={(props: any) => (
          <RankingNew {...props}/>
        )}
      />
            <Route
        path="/rankings"
        render={(props: any) => (
          <Ranking {...props}/>
        )}
      />
      <Route
        path="/tickets/:id"
        render={(props: any) => (
          <TicketForm {...props}/>
        )}
      />
      <Route
        path="/tickets"
        render={(props: any) => (
          <Tickets {...props}/>
        )}
      />
      <Route
        path="/messages"
        render={(props: any) => (
          <Messages {...props}/>
        )}
      />
      <Route
        path="/calendar"
        render={(props: any) => (
          <Calendar {...props}/>
        )}
      />
      <Route
        path="/documents"
        render={(props: any) => (
          <Documents {...props}/>
        )}
      />
      <Route
        path="/visitors/qrcode-auth/:uid"
        render={(props: any) => (
          <QrCode {...props}/>
        )}/>
      <Route
        path="/visitors/logs"
        render={(props: any) => (
          <VisitorsLogs {...props}/>
        )}
      />
      <Route
        path="/visitors/entries-log"
        render={(props: any) => (
          <EntriesLogs {...props}/>
        )}
      />
      <Route
        path="/visitors/packages-log"
        render={(props: any) => (
          <PackagesLogs {...props}/>
        )}
      />
      <Route
        path="/visitors/notes-log"
        render={(props: any) => (
          <NotesLogs {...props}/>
        )}
      />
      <Route
        path="/visitors"
        render={(props: any) => (
          <Visitors {...props}/>
        )}
      />
      <Route
        path="/concierge/car-tracker/new"
        render={(props: any) => (
          <NewCarTracker {...props}/>
        )}
      />
      <Route
        path="/concierge/car-tracker"
        render={(props: any) => (
          <CarTracker {...props}/>
        )}
      />
      <Route
        path="/checklists"
        render={(props: any) => (
          <Checklists {...props}/>
        )}
      />
      <Route
        path="/checklist/new"
        render={(props: any) => (
          <ChecklistNew {...props}/>
        )}
      />
      <Route
        path="/checklist/fill/:id"
        render={(props: any) => (
          <ChecklistFill {...props}/>
        )}
      />
      <Route
        path="/checklist/:id"
        render={(props: any) => (
          <ChecklistFill {...props}/>
        )}
      />
      <Route
        path="/meetings"
        render={(props: any) => (
          <Meetings {...props}/>
        )}
      />
      <Route
        path="/meeting/:id"
        render={(props: any) => (
          <Meeting {...props}/>
        )}
      />
      <Route
        path="/signup/employee/:id"
        render={(props: any) => (
          <EmployeeSignup {...props}/>
        )}
      />
      <Route
        path="/accounting/add"
        render={(props: any) => (
          <TransactionForm {...props}/>
        )}
      />
      <Route
        path="/accounting/approve/:id"
        render={(props: any) => (
          <TransactionForm {...props} approveMode/>
        )}
      />
      <Route
        path="/accounting/approve"
        render={(props: any) => (
          <ApproveExecuteReceiveTransaction approveMode {...props}/>
        )}
      />
      <Route
        path="/accounting/receive"
        render={(props: any) => (
          <ApproveExecuteReceiveTransaction receiveMode {...props}/>
        )}
      />
      <Route
        path="/accounting/execute/:id"
        render={(props: any) => (
          <TransactionForm {...props} executeMode/>
        )}
      />
      <Route
        path="/accounting/execute"
        render={(props: any) => (
          <ApproveExecuteReceiveTransaction executeMode {...props}/>
        )}
      />
      <Route
        path="/accounting/recurrency"
        render={(props: any) => (
          <RecurrenciesTransaction {...props}/>
        )}
      />
      <Route
        path="/accounting"
        render={(props: any) => (
          <AccountingHistory executeMode {...props}/>
        )}
      />
      <Route
        path="/"
        render={(props: any) => (
          <Dashboard {...props}/>
        )}
      />
    </Switch>)
}

export default Routes
